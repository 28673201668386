import React, { useState } from 'react';
import Countdown, { zeroPad } from "react-countdown";
import { formatPrice } from '../../hooks/contractHelper';
import { useRecordStats } from './helper/useStats';
import Button from 'react-bootstrap-button-loader';
import lockAbi from '../../json/lockabi.json';
import { getWeb3 } from '../../hooks/connectors';
import { getContract } from '../../hooks/contractHelper';
import { toast } from 'react-toastify';
import { contract } from '../../hooks/constant';
import { useAccount } from 'wagmi';
import { useEthersSigner } from '../../hooks/useEthersProvider';

export default function LockRecord() {
    const { chainId, address: account } = useAccount();
    const library = useEthersSigner({ chainId });
    const [updater, setUpdater] = useState(new Date());
    const stats = useRecordStats(updater);
    const [ctLoading, setCtLoading] = useState(false);

    const countdownrender = ({ days, hours, minutes, seconds, completed }) => {
        if (completed) {
            return (
                <div className="timer timer_1">
                    <ul>
                        <li>00<span>D</span></li>
                        <li>00<span>H</span></li>
                        <li>00<span>M</span></li>
                        <li>00<span>S</span></li>
                    </ul>
                </div>
            );
        } else {
            // Render a countdown
            return (
                <div className="timer timer_1">
                    <ul>
                        <li>{zeroPad(days, 2)}<span>D</span></li>
                        <li>{zeroPad(hours, 2)}<span>H</span></li>
                        <li>{zeroPad(minutes, 2)}<span>M</span></li>
                        <li>{zeroPad(seconds, 2)}<span>S</span></li>
                    </ul>
                </div>
            );
        }
    };


    const handleUnlock = async (e) => {
        e.preventDefault();
        setCtLoading(true);
        try {

            if (account) {
                let lockAddress = contract[chainId] ? contract[chainId].lockAddress : contract['default'].lockAddress
                let lockContract = getContract(lockAbi, lockAddress, library);

                let tx = await lockContract.unlock(stats.id, {
                    'from': account,
                });
                const resolveAfter3Sec = new Promise(resolve => setTimeout(resolve, 5000));
                toast.promise(
                    resolveAfter3Sec,
                    {
                        pending: 'Waiting for confirmation',
                    }
                )

                var interval = setInterval(async function () {
                    let web3 = getWeb3(chainId);
                    var response = await web3.eth.getTransactionReceipt(tx.hash);
                    if (response != null) {
                        clearInterval(interval)
                        if (response.status === true) {
                            toast.success('success ! your last transaction is success');
                            setUpdater(new Date());
                            setCtLoading(false);
                        }
                        else if (response.status === false) {
                            toast.error('error ! Your last transaction is failed.');
                            setUpdater(new Date());
                            setCtLoading(false);
                        }
                        else {
                            toast.error('error ! something went wrong.');
                            setUpdater(new Date());
                            setCtLoading(false);
                        }
                    }
                }, 5000);
            }
            else {
                toast.error('Please Connect to wallet !');
                setCtLoading(false);
            }

        }
        catch (err) {
            toast.error(err.reason ? err.reason : err.message);
            setCtLoading(false);
        }
    }

    return (
        <React.Fragment>
            <div class="gamfi-breadcrumbs-section">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-5">
                            <div class="breadcrumbs-area sec-heading">
                                <div class="sub-inner mb-15">
                                    <span class="sub-title">Lock</span>
                                    <img class="heading-left-image" src="../assets/images/icons/steps.png" alt="Steps" />
                                </div>
                                <h2 class="title mb-0">Lock info</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="igo-rankging-table-list pb-140 md-pb-60">
                <div class="container">
                    <div className='row justify-content-center'>
                        <div className='col-md-10 col-12'>
                            <div class="card bg-dark m-3">
                                <div class="card-header">
                                    <h4 class="card-title">Lock info</h4>
                                </div>

                                <div class="card-body">
                                    <div className='row'>
                                        <div className='col-12'>
                                            <div className='d-flex justify-content-center'>
                                                <span>Unlock In</span>
                                            </div>
                                            <div className="price-counter-new d-flex justify-content-center">
                                                <Countdown
                                                    key={Math.floor(Math.random() * 10 + 1)}
                                                    date={stats.tgeDate * 1000}
                                                    renderer={countdownrender}
                                                />

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-10 col-12'>
                            <div class="card bg-dark m-3">
                                <div class="card-header">
                                    <h4 class="card-title">Token Info</h4>
                                </div>

                                <div class="card-body">
                                    <div className='mt-3 d-flex justify-content-between card-span'>
                                        <span>Token Address</span>
                                        <span className="step-input-value">{stats.TokenAddress}</span>
                                    </div>
                                    <div className='mt-3 d-flex justify-content-between card-span'>
                                        <span>Token Name</span>
                                        <span className="step-input-value">{stats.TokenName}</span>
                                    </div>
                                    <div className='mt-3 d-flex justify-content-between card-span'>
                                        <span>Token Symbol</span>
                                        <span className="step-input-value">{stats.TokenSymbol}</span>
                                    </div>
                                    <div className='mt-3 d-flex justify-content-between card-span'>
                                        <span>Token Decimals</span>
                                        <span className="step-input-value">{stats.TokenDecimals}</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='col-md-10 col-12'>
                            <div class="card bg-dark m-3">
                                <div class="card-header">
                                    <h4 class="card-title">Lock info</h4>
                                </div>

                                <div class="card-body">
                                    <div className='mt-3 d-flex justify-content-between card-span'>
                                        <span>Title</span>
                                        <span className="step-input-value">{stats.description}</span>
                                    </div>
                                    <div className='mt-3 d-flex justify-content-between card-span'>
                                        <span>Total Amount Locked</span>
                                        <span className="step-input-value">{formatPrice(stats.amount)} {stats.TokenSymbol}</span>
                                    </div>
                                    <div className='mt-3 d-flex justify-content-between card-span'>
                                        <span>Owner</span>
                                        <span className="step-input-value">{stats.owner}</span>
                                    </div>
                                    <div className='mt-3 d-flex justify-content-between card-span'>
                                        <span>Lock Date</span>
                                        <span className="step-input-value">{stats.lockDate ? new Date(stats.lockDate * 1000).toUTCString().substring(4, 25) : '-'} UTC</span>
                                    </div>
                                    {stats.cycle <= 0 ? (
                                        <div className='mt-3 d-flex justify-content-between card-span'>
                                            <span>Unlock Date</span>
                                            <span className="step-input-value">{stats.tgeDate ? new Date(stats.tgeDate * 1000).toUTCString().substring(4, 25) : '-'} UTC</span>
                                        </div>
                                    ) : (
                                        <React.Fragment>
                                            <div className='mt-3 d-flex justify-content-between card-span'>
                                                <span>TGE Date</span>
                                                <span className="step-input-value">{stats.tgeDate ? new Date(stats.tgeDate * 1000).toUTCString().substring(4, 25) : '-'} UTC</span>
                                            </div>
                                            <div className='mt-3 d-flex justify-content-between card-span'>
                                                <span>TGE Percent</span>
                                                <span className="step-input-value">{stats.cycleBps ? stats.cycleBps / 100 : 0}%</span>
                                            </div>
                                            <div className='mt-3 d-flex justify-content-between card-span'>
                                                <span>Cycle</span>
                                                <span className="step-input-value">{stats.cycle ? stats.cycle / 60 : 0} minutes</span>
                                            </div>
                                            <div className='mt-3 d-flex justify-content-between card-span'>
                                                <span>Cycle Release Percent</span>
                                                <span className="step-input-value">{stats.tgeBps ? stats.tgeBps / 100 : 0} %</span>
                                            </div>

                                        </React.Fragment>
                                    )
                                    }
                                    <div className='mt-3 d-flex justify-content-between card-span'>
                                        <span>Unlocked Amount</span>
                                        <span className="step-input-value">{stats.unlockedAmount ? stats.unlockedAmount : 0} {stats.TokenSymbol}</span>
                                    </div>
                                    {account && account.toLowerCase() === stats.owner.toLowerCase() &&
                                        <div className='mt-3 d-flex justify-content-center'>
                                            <Button variant="none" loading={ctLoading} className='default-btn next-step btn btn-primary' onClick={(e) => handleUnlock(e)}>Unlock{stats.withdrawableTokens > 0 ? `(${stats.withdrawableTokens} ${stats.TokenSymbol})` : ''}</Button>
                                        </div>
                                    }

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}
