export const supportNetwork = {
    1: {
        name: "ETHEREUM",
        chainId: 1,
        rpc: "https://mainnet.infura.io/v3/2Kdnmw3iNgznnwhpHu25OfBJWoT",
        image: "etherum.png",
        symbol: 'ETH'
    },
    // 3 : {
    //     name : "ROPSTEN",
    //     chainId : 3,
    //     rpc : "https://ropsten.infura.io/v3/63f4b8ee61284419b46c780d03befc4e",
    //     image : "etherum.png",
    //     symbol : 'ETH'
    // },
    // 4 : {
    //     name : "RINKEBY",
    //     chainId : 4,
    //     rpc : "https://rinkeby.infura.io/v3/63f4b8ee61284419b46c780d03befc4e",
    //     image : "etherum.png",
    //     symbol : 'RETH'
    // },
    56: {
        name: "BINANCE",
        chainId: 56,
        rpc: "https://bsc-dataseed1.defibit.io/",
        image: "binance-coin.png",
        symbol: 'BNB'
    },
    // 97 : {
    //     name : "TBINANCE",
    //     chainId : 97,
    //     rpc : "https://data-seed-prebsc-1-s1.binance.org:8545/",
    //     image : "binance-coin.png",
    //     symbol : 'BNB'
    // },
    137: {
        name: "POLYGON",
        chainId: 137,
        rpc: "https://polygon-rpc.com/",
        image: "polygon.svg",
        symbol: 'MATIC'
    },
    43114: {
        name: "Avalanche",
        chainId: 43114,
        rpc: "https://api.avax.network/ext/bc/C/rpc",
        image: "avalanche.svg",
        symbol: 'AVAX'
    },
    // 55 : {
    //     name : "BlockStar Testnet",
    //     chainId : 55,
    //     rpc : "https://testnet-rpc.blockstar.one",
    //     image : "blockstar.svg",
    //     symbol : 'BST'
    // },
    5512: {
        name: "BlockStar",
        chainId: 5512,
        rpc: "https://mainnet-rpc.blockstar.one",
        image: "blockstar.svg",
        symbol: 'BST'
    },
    'default': {
        name: "BlockStar",
        chainId: 5512,
        rpc: "https://mainnet-rpc.blockstar.one",
        image: "blockstar.svg",
        symbol: 'BST'
    }

}

export const RPC_URLS = {
    56: "https://bsc-dataseed1.defibit.io/",
    // 55: "https://testnet-rpc.blockstar.one",
    5512: "https://mainnet-rpc.blockstar.one",
    // 97: "https://data-seed-prebsc-1-s1.binance.org:8545/",
    // 3: "https://ropsten.infura.io/v3/63f4b8ee61284419b46c780d03befc4e",
    // 4: "https://rinkeby.infura.io/v3/63f4b8ee61284419b46c780d03befc4e",
    137: "https://polygon-rpc.com/",
    43114: "https://api.avax.network/ext/bc/C/rpc",
    1: "https://mainnet.infura.io/v3/2Kdnmw3iNgznnwhpHu25OfBJWoT"
};

