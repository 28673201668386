import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Api_Baseurl } from '../../../../hooks/constant';
import axios from 'axios';
import { toast } from 'react-toastify';
import { supportNetwork } from '../../../../hooks/network';
import Countdown , {zeroPad} from "react-countdown";
import HashLoader from "react-spinners/HashLoader";
import { formatPrice } from '../../../../hooks/contractHelper';


export default function Opensale(props) {
    let {chainSelect , search} = props;
    const [dataload, setDataload] = useState(true);
    

    const [page, setPage] = useState(0);
    const [pageSize] = useState(5);
    const [openSale, setOpenSale] = useState([]);
    const [count, setCount] = useState(0);
    const [currentTime] = useState(Math.floor(new Date().getTime() / 1000.0));

    useEffect(() => {
        async function fetchData() {
            axios.get(`${Api_Baseurl}api/pooldata/getActivePools`, {
                params: {
                    page: page,
                    pageSize: pageSize,
                    chainId : chainSelect.chainId,
                    tokenName : search,
                    ispresale:'2'
                }
            })
                .then(function (response) {
                    if (response) {
                        let data = response.data;
                        if (data.error === 'OK') {
                            setOpenSale(data.data);
                            setDataload(false);
                            setCount(data.count);
                        }
                        else {
                            toast.error('Something went wrong ! try again')

                        }
                    }
                })
                .catch(function (error) {
                    toast.error('Something went wrong ! try again')
                })
        }

        fetchData();
        // eslint-disable-next-line
    }, [props]);

    const countdownrender = ({ days, hours, minutes, seconds, completed }) => {
        if (completed) {
            return (
                <div className="timer timer_1">
                    <ul>
                        <li><span className="counter">00</span>D</li>
                        <li><span className="counter">00</span>H</li>
                        <li><span className="counter">00</span>M</li>
                        <li><span className="counter">00</span>S</li>
                    </ul>
                </div>
            );
        } else {
            // Render a countdown
            return (
                <div className="timer timer_1">
                    <ul>
                        <li><span className="counter">{zeroPad(days,2)}</span>D</li>
                        <li><span className="counter">{zeroPad(hours,2)}</span>H</li>
                        <li><span className="counter">{zeroPad(minutes,2)}</span>M</li>
                        <li><span className="counter">{zeroPad(seconds,2)}</span>S</li>
                    </ul>
                </div>
            );
        }
    };

    const handleNextPage = (e) => {
        e.preventDefault();
        setDataload(true);

        axios.get(`${Api_Baseurl}api/pooldata/getPools`, {
            params: {
                page: parseInt(page) + 1,
                pageSize: pageSize,
                chainId : chainSelect.chainId,
                tokenName : search,
                ispresale:'2'
            }
        })
            .then(function (response) {
                if (response) {
                    let data = response.data;
                    if (data.error === 'OK') {
                        setOpenSale(data.data);
                        setDataload(false);
                        setPage(parseInt(page) + 1);
                        setCount(data.count);
                        window.scrollTo(0, 0);
                    }
                    else {
                        toast.error('Something went wrong ! try again')

                    }
                }
            })
            .catch(function (error) {
                toast.error('Something went wrong ! try again')
            })

    }

    const handlePreviousPage = (e) => {
        e.preventDefault();
        setDataload(true);

        axios.get(`${Api_Baseurl}api/pooldata/getPools`, {
            params: {
                page: parseInt(page) - 1,
                pageSize: pageSize,
                chainId : chainSelect.chainId,
                tokenName : search,
                ispresale:'2'
            }
        })
            .then(function (response) {
                if (response) {
                    let data = response.data;
                    if (data.error === 'OK') {
                        setOpenSale(data.data);
                        setDataload(false);
                        setPage(parseInt(page) - 1);
                        setCount(data.count);
                        window.scrollTo(0, 0);
                    }
                    else {
                        toast.error('Something went wrong ! try again')

                    }
                }
            })
            .catch(function (error) {
                toast.error('Something went wrong ! try again')
            })

    }

    return (
        <div className="tab-pane fade show active" id="open-igo" role="tabpanel" aria-labelledby="home-tab">
            <div className="row align-items-center">
                <div className="col-md-12">
                    <div className="previous-mainmenu mb-15">
                        <ul className="menu-list">
                            <li className="list1">Project</li>
                            <li className="list2">Chain</li>
                            <li className="list3">End</li>
                            <li className="list4">Total Raise</li>
                            <li className="list5">Progress</li>
                        </ul>
                    </div>
                </div>
                {dataload ? (
                    <div className='col-md-12'>
                        <HashLoader size="100" color="#fff" cssOverride={{ "left": "50%", "textAlign": "center", "top": "50%" }} />

                    </div>
                ) : (
                    openSale.map((rowdata, index) => {
                        let chainSymbol = supportNetwork[rowdata.chainId] ? supportNetwork[rowdata.chainId].image : supportNetwork['default'].image
                        let currencySymbol = supportNetwork[rowdata.chainId] ? supportNetwork[rowdata.chainId].symbol : supportNetwork['default'].symbol
                        return (
                            <div className="col-md-12" key={index}>
                                <Link to={`/privatesale-details/${rowdata.poolAddress}?chainid=${rowdata.chainId}`}>
                                    <div className="previous-item hover-shape-border hover-shape-inner">
                                        <div className="previous-gaming">
                                            <div className="previous-image">
                                                <img src={rowdata.logourl} alt="PreviousImage12" height="80px" width="80px" />
                                            </div>
                                            <div className="previous-price">
                                                <h4 className="mb-10">{rowdata.tokenName}</h4>
                                                <div className="dsc">1 {currencySymbol} ~ {formatPrice(rowdata.rate)} {rowdata.tokenSymbol}</div>
                                            </div>
                                        </div>
                                        <div className="previous-chaining">
                                            <img src={`../assets/images/${chainSymbol}`} alt="ChainImage12" width="40px" height="40px" />
                                            <div className="counter-info">
                                                <Countdown
                                                    key={Math.floor(Math.random() * 10 + 1)}
                                                    date={rowdata.startTime > currentTime ? rowdata.startTime * 1000 : rowdata.endTime * 1000}
                                                    renderer={countdownrender}
                                                />
                                            </div>
                                        </div>
                                        <div className="previous-raise">
                                            <span>{rowdata.totalRaised} {currencySymbol}  ({rowdata.percentageRaise}%)</span>
                                            <div className="progress-inner">
                                                <div className="progress">
                                                    <div className="progress-bar progress-bar-striped" role="progressbar" aria-valuenow={rowdata.percentageRaise} aria-valuemin="0" aria-valuemax="100" style={{ "width": `${rowdata.percentageRaise}%` }}>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <span className="border-shadow shadow-1"></span>
                                        <span className="border-shadow shadow-2"></span>
                                        <span className="border-shadow shadow-3"></span>
                                        <span className="border-shadow shadow-4"></span>
                                        <span className="hover-shape-bg hover_shape1"></span>
                                        <span className="hover-shape-bg hover_shape2"></span>
                                        <span className="hover-shape-bg hover_shape3"></span>
                                    </div>
                                </Link>
                            </div>
                        )
                    })

                )}

            </div>
            <div className="gamfi-navigation text-center mt-10">
                <ul>
                    <li>
                        {page > 0 &&
                            <a href="#sec" onClick={(e) => handlePreviousPage(e)}>
                                <i className="icon-Vector mr-2"></i>Previous
                            </a>
                        }
                    </li>
                    {Math.floor(count / pageSize) > parseFloat(page) &&
                        < li >
                            <a href="#sec" onClick={(e) => handleNextPage(e)}>
                                Next<i className="icon-arrow_right ml-2"></i>
                            </a>
                        </li>
                    }
                </ul>
            </div>
        </div >
    )
}
