import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { supportNetwork } from '../../../../hooks/network';
import Countdown ,{zeroPad} from "react-countdown";
import HashLoader from "react-spinners/HashLoader";
import { formatPrice } from '../../../../hooks/contractHelper';
import { usePoolListUser } from '../helper/useStats';



export default function MyContributions() {
    
    const [updater,setUpdater] = useState({page : 0 , pageSize : 7 , loading : true});
    const stats = usePoolListUser(updater);
    const [currentTime] = useState(Math.floor(new Date().getTime() / 1000.0));
   
    const countdownrender = ({ days, hours, minutes, seconds, completed }) => {
        if (completed) {
            return (
                <div className="timer timer_1">
                    <ul>
                    <li><span className="counter">{zeroPad(days,2)}</span>D</li>
                        <li><span className="counter">{zeroPad(hours,2)}</span>H</li>
                        <li><span className="counter">{zeroPad(minutes,2)}</span>M</li>
                        <li><span className="counter">{zeroPad(seconds,2)}</span>S</li>
                    </ul>
                </div>
            );
        } else {
            // Render a countdown
            return (
                <div className="timer timer_1">
                    <ul>
                        <li><span className="counter">{days}</span>D</li>
                        <li><span className="counter">{hours}</span>H</li>
                        <li><span className="counter">{minutes}</span>M</li>
                        <li><span className="counter">{seconds}</span>S</li>
                    </ul>
                </div>
            );
        }
    };

   return (
        <div className="tab-pane fade" id="upcoming" role="tabpanel" aria-labelledby="profile-tab">
            <div className="row align-items-center">
                <div className="col-md-12">
                    <div className="previous-mainmenu mb-15">
                        <ul className="menu-list">
                            <li className="list1">Project</li>
                            <li className="list2">Chain</li>
                            <li className="list3">End</li>
                            <li className="list4">Total Raise</li>
                            <li className="list5">Progress</li>
                            <li className="list6">Type</li>
                        </ul>
                    </div>
                </div>
                {stats.loading ? (
                    <div className='col-md-12'>
                        <HashLoader size="100" color="#fff" cssOverride={{ "left": "50%", "textAlign": "center", "top": "50%" }} />
                    </div>
                ) : (
                    stats.poolList.length > 0 ? (
                        stats.poolList.slice(0).reverse().map((rowdata, index) => {
                            let chainSymbol = supportNetwork[stats.chainId] ? supportNetwork[stats.chainId].image : supportNetwork['default'].image
                            let currencySymbol = supportNetwork[stats.chainId] ? supportNetwork[stats.chainId].symbol : supportNetwork['default'].symbol
                            return (
                                <div className="col-md-12" key={index}>
                                    <Link to={ rowdata.poolType === '0' ? `/presale-details/${rowdata.poolAddress}?chainid=${stats.chainId}` : rowdata.poolType === '1' ? `/privatesale-details/${rowdata.poolAddress}?chainid=${stats.chainId}` : rowdata.poolType === '2' ? `/fair-details/${rowdata.poolAddress}?chainid=${stats.chainId}` : '/'}>
                                        <div className="previous-item hover-shape-border hover-shape-inner">
                                            <div className="previous-gaming">
                                                <div className="previous-image">
                                                    <img src="https://pinksale.infura-ipfs.io/ipfs/QmZHQn2Pn9EGMhfq5AeH35WqkC5pAjkFyTd1ogEpAKpitp" alt="PreviousImage12" height="80px" width="80px" />
                                                </div>
                                                <div className="previous-price">
                                                    <h4 className="mb-10">{rowdata.name}</h4>
                                                    <div className="dsc">1 {currencySymbol} ~ {rowdata.poolType === '2' ? 'N/A' : formatPrice(rowdata.rate)} {rowdata.symbol}</div>
                                                </div>
                                            </div>
                                            <div className="previous-chaining">
                                                <img src={`../assets/images/${chainSymbol}`} alt="ChainImage12" width="40px" height="40px" />
                                                <div className="counter-info">
                                                    <Countdown
                                                        key={Math.floor(Math.random() * 10 + 1)}
                                                        date={rowdata.startTime > currentTime ? rowdata.startTime * 1000 : rowdata.endTime * 1000}
                                                        renderer={countdownrender}
                                                    />
                                                </div>
                                            </div>
                                            <div className="previous-raise">
                                                <span>{rowdata.totalRaised} {currencySymbol}  ({parseFloat(rowdata.percentageRaise).toFixed(2)}%)</span>
                                                <div className="progress-inner">
                                                    <div className="progress">
                                                        <div className="progress-bar progress-bar-striped" role="progressbar" aria-valuenow={parseFloat(rowdata.percentageRaise).toFixed(2)} aria-valuemin="0" aria-valuemax="100" style={{ "width": `${parseFloat(rowdata.percentageRaise).toFixed(2)}%` }}>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                {rowdata.poolType === '0' ?
                                                <h4 class="tag-btn text-uppercase text-center bg-yellow">Presale</h4> :
                                                // <span className='badge badge-success'>Presale</span> : 
                                                rowdata.poolType === '1' ? <h4 class="tag-btn text-uppercase text-center bg-yellow">Privatesale</h4> : 
                                                rowdata.poolType === '2' ? <h4 class="tag-btn text-uppercase text-center bg-yellow">FairLaunch</h4> : ' ~ '}
                                            </div>
                                            <span className="border-shadow shadow-1"></span>
                                            <span className="border-shadow shadow-2"></span>
                                            <span className="border-shadow shadow-3"></span>
                                            <span className="border-shadow shadow-4"></span>
                                            <span className="hover-shape-bg hover_shape1"></span>
                                            <span className="hover-shape-bg hover_shape2"></span>
                                            <span className="hover-shape-bg hover_shape3"></span>
                                        </div>
                                    </Link>
                                </div>
                            )
                        })
                    ):(
                        <div className='d-flex justify-content-center'></div>
                    )

                )}

            </div>
            <div className="gamfi-navigation text-center mt-10">
                        <ul>
                            <li>
                                {stats.page > 0 &&
                                    <a href="#sec" onClick={(e) => setUpdater({page : stats.page - 1 , pageSize : stats.pageSize , loading : true})}>
                                        <i className="icon-Vector mr-2"></i>Previous
                                    </a>
                                }
                            </li>
                            {Math.floor(stats.getTotalNumberOfPools / stats.pageSize) > stats.page &&
                                < li >
                                    <a href="#sec" onClick={(e) => setUpdater({page : stats.page + 1 , pageSize : stats.pageSize , loading : true})}>
                                        Next<i className="icon-arrow_right ml-2"></i>
                                    </a>
                                </li>
                            }
                        </ul>
                    </div>
        </div >
    )
}
