import React , {useState} from 'react';
import MyContributions from './component/MyContributions';
import Opensale from './component/Opensale';


export default function ProjectList() {

    const [chainSelect, setChainSelect] = useState({
        chainId: null,
        name: 'All Block Chain',
        isFilter : false,
        search : null,
        ispresale : null,
        saleName : 'All',
        saleStatus : "All",
        saleStatusId : null
    });

    const handleChainChanged = (e) => {
        setChainSelect({
            ...chainSelect,
            isFilter : chainSelect.saleStatus === 'All' && e.target.name === 'All Block Chain' && chainSelect.ispresale === null && (chainSelect.search === null || chainSelect.search === '') ? false : true,
            chainId: e.target.id,
            name: e.target.name
        });

    }

    const handleSaleStatus = (e) => {
        setChainSelect({
            ...chainSelect,
            isFilter : e.target.name === 'All'  && chainSelect.saleName === 'All' && chainSelect.name === 'All Block Chain'  && (chainSelect.search === null || chainSelect.search === '') ? false : true,
            saleStatusId: e.target.id,
            saleStatus: e.target.name
        });
    }

    const handleSaleChanged = (e) => {
        setChainSelect({
            ...chainSelect,
            isFilter : chainSelect.saleStatus === 'All' &&  e.target.name === 'All' && chainSelect.name === 'All Block Chain'  && (chainSelect.search === null || chainSelect.search === '') ? false : true,
            ispresale: e.target.id,
            saleName: e.target.name
        });

    }

    const handleSearch = (e) => {
        setChainSelect({
            ...chainSelect,
            search : e.target.value,
            isFilter : chainSelect.saleStatus === 'All' && chainSelect.name === 'All Block Chain' && chainSelect.ispresale === null && (e.target.value === '' || e.target.value === null)  ? false : true,
        });
    }

    return (
        <React.Fragment>
            {/* <!-- Breadcrumbs Section Start --> */}
            <div className="gamfi-breadcrumbs-section">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-5">
                            <div className="breadcrumbs-area sec-heading">
                                <div className="sub-inner mb-15">
                                    <span className="sub-title">Projects</span>
                                    <img className="heading-left-image" src="assets/images/icons/steps.png" alt="Steps-mage" />
                                </div>
                                <h2 className="title mb-0">Explore ICOs/IDOs</h2>
                            </div>
                        </div>
                        <div className="col-lg-7 breadcrumbs-form md-pt-30">
                            <form>
                                <input type="text" value={chainSelect.search === 'null' ? '' : chainSelect.search} onChange={(e) => { handleSearch(e) }} name="search" placeholder="Search by name, token, address" />
                                <span className="submit"><i className="icon-search"></i></span>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- Breadcrumbs  Section End --> */}

            {/* <!-- Previous Section Start --> */}
            <div className="gamfi-previous-section pt-20 pb-120 md-pb-80">
                <div className="container">
                    <div className="project-menu-area d-flex align-items-center justify-content-between">
                        <div className="project-left-menu">
                            <ul className="nav" id="myTab" role="tablist">
                                <li className="nav-item" role="presentation">
                                    <button className="tab-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#open-igo" type="button" role="tab" aria-controls="open-igo" aria-selected="true">All Launchpad</button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button className="tab-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#upcoming" type="button" role="tab" aria-controls="upcoming" aria-selected="false">My Contributions</button>
                                </li>
                            </ul>
                        </div>
                        <div className="project-right-menu">
                            <ul className="al-access-menu">
                                <li className="block"><a href="#sec">{chainSelect.name ? chainSelect.name : 'All Block Chain '}</a>
                                    <ul className="sub-menu">
                                        <li><a href="#sec" name="All Block Chain" id="null" onClick={(e) => handleChainChanged(e)}>All Block Chain</a></li>
                                        <li><a href="#sec" name="BlockStar (BST)" id="5512" onClick={(e) => handleChainChanged(e)}><img src="assets/images/blockstar.svg" alt="icon" /> BlockStar (BST)</a></li>
                                        <li><a href="#sec" name="Binance (BSC)" id="56" onClick={(e) => handleChainChanged(e)}><img src="assets/images/project/previous-image.png" alt="icon" /> Binance (BSC)</a></li>
                                        <li><a href="#sec" name="Ethereum (ETH)"  id="1" onClick={(e) => handleChainChanged(e)}><img src="assets/images/project/previous-image2.png" alt="icon" /> Ethereum (ETH)</a></li>
                                        <li><a href="#sec" name="Polygon (MATIC)"  id="137" onClick={(e) => handleChainChanged(e)}><img src="assets/images/polygon.svg" alt="icon" /> Polygon (MATIC)</a></li>
                                        <li><a href="#sec" name="Avalanche (AVAX)"  id="43114" onClick={(e) => handleChainChanged(e)}><img src="assets/images/avalanche.svg" alt="icon" /> Avalanche (AVAX)</a></li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                        <div className="project-right-menu">
                            <ul className="al-access-menu">
                                <li className="block"><a href="#sec">{chainSelect.saleName ? chainSelect.saleName : 'All'}</a>
                                    <ul className="sub-menu">
                                        <li><a href="#sec" name="All" id="null" onClick={(e) => handleSaleChanged(e)}>All</a></li>
                                        <li><a href="#sec" name="PreSale" id="0" onClick={(e) => handleSaleChanged(e)}> PreSale</a></li>
                                        <li><a href="#sec" name="PrivateSale" id="1" onClick={(e) => handleSaleChanged(e)}> PrivateSale</a></li>
                                        <li><a href="#sec" name="FairLaunch" id="2" onClick={(e) => handleSaleChanged(e)}>FairLaunch</a></li>
                                    </ul>
                                </li>
                            </ul>
                        </div>

                        <div className="project-right-menu">
                            <ul className="al-access-menu">
                                <li className="block"><a href="#sec">{chainSelect.saleStatus ? chainSelect.saleStatus : 'All'}</a>
                                    <ul className="sub-menu">
                                        <li><a href="#sec" name="All" id="null" onClick={(e) => handleSaleStatus(e)}>All</a></li>
                                        <li><a href="#sec" name="Upcoming" id="1" onClick={(e) => handleSaleStatus(e)}> Upcoming</a></li>
                                        <li><a href="#sec" name="OnGoing" id="2" onClick={(e) => handleSaleStatus(e)}> OnGoing</a></li>
                                        <li><a href="#sec" name="Ended" id="3" onClick={(e) => handleSaleStatus(e)}>Ended</a></li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </div>
                    {/* <!-- Tab Menu Section End --> */}

                    {/* <!-- Tab Content Section Start --> */}
                    <div className="tab-content" id="myTabContent">
                        {/* <!-- Open ICO Content Start --> */}
                        <Opensale  chainSelect={chainSelect}  />
                        {/* <!-- Open ICO Content End --> */}

                        {/* <!-- Upcoming Content Start --> */}
                        <MyContributions />
                        {/* <!-- Upcoming Content Start --> */}

                        {/* <!-- Past ICO Content Start --> */}
                        {/* <PastSale chainSelect={chainSelect} search={search} /> */}
                    </div >
                </div >
            </div >
            {/* <!-- Previous Section End --> */}
        </React.Fragment >
    )
}
