import { useEffect, useState } from "react"
import presalePoolAbi from '../../../../json/presalePool.json';
import poolManagerAbi from '../../../../json/poolManager.json';
import { MulticallContractWeb3, multiCallContractConnect } from "../../../../hooks/useContracts";
import { getWeb3 } from "../../../../hooks/connectors";
import tokenAbi from '../../../../json/token.json';
import lockAbi from '../../../../json/lockabi.json';
import { useLocation, useHistory } from 'react-router-dom';
import { toast } from "react-toastify";
import { contract } from "../../../../hooks/constant";
import { supportNetwork } from "../../../../hooks/network";
import axios from "axios";
import { Api_Baseurl } from "../../../../hooks/constant";
import { useAccount } from "wagmi";



export const useCommonStats = (update) => {
  const { chainId } = useAccount();
  let history = useHistory();

  // let poolAddress = "0x6816e27bca20fbbe779ca9725d48c1e01a02943c";

  const location = useLocation();
  let urlAddress = location.pathname.split('/').pop();
  const search = useLocation().search;
  const queryChainId = new URLSearchParams(search).get('chainid');
  let web3 = getWeb3(queryChainId ? queryChainId : chainId);

  let poolContract = new web3.eth.Contract(presalePoolAbi, urlAddress);

  const [stats, setStats] = useState({
    endTime: 0,
    startTime: 0,
    hardCap: 0,
    softCap: 0,
    maxContribution: 0,
    poolDetails: 0,
    poolState: 0,
    rate: 0,
    remainingContribution: 0,
    tgeLockDuration: 0,
    tgeTime: 0,
    tgeTokenReleasePer: 0,
    tgeTokensRelease: 0,
    token: 0,
    tokensReleaseEachCycle: 0,
    totalClaimed: 0,
    totalRaised: 0,
    totalVestingTokens: 0,
    useWhitelisting: 0,
    minContribution: 0,
    tokenName: '',
    tokenDecimal: 0,
    tokenSymbol: 0,
    tokenSupply: 0,
    percentageRaise: 0,
    refundType: 0,
    eachvestingPer: 0,
    cycle: 0,
    poolAddress: 0,
    governance: 0,
    kyc: false,
    audit: false,
    auditStatus: false,
    kycStatus: false,
    partnerList: [],
    insurcePrice: 0,
    kycLink: '#',
    auditLink: '#',
    currencySymbol: supportNetwork[queryChainId ? queryChainId : chainId] ? supportNetwork[queryChainId ? queryChainId : chainId].symbol : supportNetwork['default'].symbol
  });

  const mc = MulticallContractWeb3(queryChainId ? queryChainId : chainId);
  let poolManagerAddress = contract[queryChainId ? queryChainId : chainId] ? contract[queryChainId ? queryChainId : chainId].poolmanager : contract['default'].poolmanager;
  let pmc = new web3.eth.Contract(poolManagerAbi, poolManagerAddress);
  let lockAddress = contract[queryChainId ? queryChainId : chainId] ? contract[queryChainId ? queryChainId : chainId].lockAddress : contract['default'].lockAddress;
  let lmc = new web3.eth.Contract(lockAbi, lockAddress);

  useEffect(() => {
    const fetch = async () => {
      try {

        const data = await mc.aggregate([
          poolContract.methods.endTime(),
          poolContract.methods.startTime(),
          poolContract.methods.hardCap(),
          poolContract.methods.softCap(),
          poolContract.methods.maxContribution(),
          poolContract.methods.poolDetails(),
          poolContract.methods.poolState(),
          poolContract.methods.rate(),
          poolContract.methods.remainingContribution(),
          poolContract.methods.tgeLockDuration(),
          poolContract.methods.tgeTime(),
          poolContract.methods.tgeTokenReleasePer(),
          poolContract.methods.tgeTokensRelease(),
          poolContract.methods.token(),
          poolContract.methods.tokensReleaseEachCycle(),
          poolContract.methods.totalClaimed(),
          poolContract.methods.totalRaised(),
          poolContract.methods.totalVestingTokens(),
          poolContract.methods.useWhitelisting(),
          poolContract.methods.minContribution(),
          poolContract.methods.refundType(),
          poolContract.methods.eachvestingPer(),
          poolContract.methods.cycle(),
          poolContract.methods.governance(),
          poolContract.methods.kyc(),
          poolContract.methods.audit(),
          poolContract.methods.auditStatus(),
          poolContract.methods.kycStatus(),
          pmc.methods.poolPartnerList(urlAddress)
        ]);



        let tokenContract = new web3.eth.Contract(tokenAbi, data[13]);

        const tokendata = await mc.aggregate([
          tokenContract.methods.name(),
          tokenContract.methods.decimals(),
          tokenContract.methods.symbol(),
          tokenContract.methods.totalSupply(),
          tokenContract.methods.balanceOf('0x000000000000000000000000000000000000dead'),
        ]);

        const lockdata = await mc.aggregate([
          lmc.methods.cumulativeLockInfo(data[13]),
          pmc.methods.getInsurancePrice()
        ]);


        let partnerData = await axios.get(`${Api_Baseurl}api/admin/get-kyc-link`, {
          params: {
            poolAddress: urlAddress
          }
        });

        setStats({
          endTime: data[0],
          startTime: data[1],
          hardCap: data[2] / Math.pow(10, 18),
          softCap: data[3] / Math.pow(10, 18),
          maxContribution: data[4] / Math.pow(10, 18),
          poolDetails: data[5],
          poolState: data[6],
          rate: data[7] / Math.pow(10, tokendata[1]),
          remainingContribution: data[8] / Math.pow(10, 18),
          tgeLockDuration: data[9],
          tgeTime: data[10],
          tgeTokenReleasePer: data[11],
          tgeTokensRelease: data[12],
          token: data[13],
          tokensReleaseEachCycle: data[14],
          totalClaimed: data[15],
          totalRaised: data[16] / Math.pow(10, 18),
          totalVestingTokens: data[17],
          useWhitelisting: data[18],
          minContribution: data[19] / Math.pow(10, 18),
          tokenName: tokendata[0],
          tokenDecimal: tokendata[1],
          tokenSymbol: tokendata[2],
          percentageRaise: ((data[16] / Math.pow(10, 18)) / (data[2] / Math.pow(10, 18))) * 100,
          tokenSupply: tokendata[3] / Math.pow(10, tokendata[1]),
          refundType: data[20],
          eachvestingPer: data[21],
          cycle: data[22],
          poolAddress: urlAddress,
          governance: data[23],
          kyc: data[24],
          audit: data[25],
          auditStatus: data[26],
          kycStatus: data[27],
          partnerList: data[28],
          currencySymbol: supportNetwork[queryChainId ? queryChainId : chainId] ? supportNetwork[queryChainId ? queryChainId : chainId].symbol : supportNetwork['default'].symbol,
          totalPresaleToken: parseFloat((data[11] / Math.pow(10, tokendata[1])) * (data[2] / Math.pow(10, 18))) + parseFloat(((data[2] / Math.pow(10, 18)) * (data[4] / Math.pow(10, tokendata[1])) * data[6]) / 100),
          PresalePer: (((data[2] / Math.pow(10, 18)) * (data[7] / Math.pow(10, tokendata[1]))) / (tokendata[3] / Math.pow(10, tokendata[1]))) * 100,
          LiquidityPer: 0,
          BurntPer: ((tokendata[4] / Math.pow(10, tokendata[1])) / (tokendata[3] / Math.pow(10, tokendata[1]))) * 100,
          LockPer: lockdata[0] ? ((lockdata[0][2] / Math.pow(10, tokendata[1])) / (tokendata[3] / Math.pow(10, tokendata[1]))) * 100 : 0,
          insurcePrice: lockdata[1] / Math.pow(10, 18),
          kycLink: typeof partnerData.data !== 'undefined' && partnerData.data.error === 'OK' ? partnerData.data.data.kycLink : '#sec',
          auditLink: typeof partnerData.data !== 'undefined' && partnerData.data.error === 'OK' ? partnerData.data.data.auditLink : '#sec',
        });
      }
      catch (err) {
        console.log(err.message);
        toast.error('wrong network selected !');
        history.push('/sale-list');
      }
    }

    if (mc) {
      fetch();
    }
    else {
      setStats({
        endTime: 0,
        startTime: 0,
        hardCap: 0,
        softCap: 0,
        maxContribution: 0,
        poolDetails: 0,
        poolState: 0,
        rate: 0,
        remainingContribution: 0,
        tgeLockDuration: 0,
        tgeTime: 0,
        tgeTokenReleasePer: 0,
        tgeTokensRelease: 0,
        token: 0,
        tokensReleaseEachCycle: 0,
        totalClaimed: 0,
        totalRaised: 0,
        totalVestingTokens: 0,
        useWhitelisting: 0,
        minContribution: 0,
        tokenName: '',
        tokenDecimal: 0,
        tokenSymbol: 0,
        percentageRaise: 0,
        tokenSupply: 0,
        refundType: 0,
        eachvestingPer: 0,
        cycle: 0,
        poolAddress: 0,
        governance: 0,
        kyc: false,
        audit: false,
        auditStatus: false,
        kycStatus: false,
        partnerList: [],
        insurcePrice: 0,
        kycLink: '#',
        auditLink: '#',
        currencySymbol: supportNetwork[queryChainId ? queryChainId : chainId] ? supportNetwork[queryChainId ? queryChainId : chainId].symbol : supportNetwork['default'].symbol
      })
    }
    // eslint-disable-next-line
  }, [update, chainId]);

  return stats;
}

export const useAccountStats = (updater) => {
  const { chainId, address:account  } = useAccount();
  const location = useLocation();
  let history = useHistory();
  let urlAddress = location.pathname.split('/').pop();
  const search = useLocation().search;
  const queryChainId = new URLSearchParams(search).get('chainid');

  let web3 = getWeb3(queryChainId ? queryChainId : chainId);

  try {
    let poolAddress = web3.utils.isAddress(urlAddress);
    let isCode = web3.eth.getCode(urlAddress);
    if (isCode === '0x' || !poolAddress) {
      history.push('/project-details');
    }
  }
  catch (err) {
    history.push('/');
  }



  let poolContract = new web3.eth.Contract(presalePoolAbi, urlAddress);

  const [stats, setStats] = useState({
    balance: '',
    contributionOf: '',
    userAvalibleClaim: '',
    haveInsurance: false
  });

  const mc = MulticallContractWeb3(queryChainId ? queryChainId : chainId);
  const mcc = multiCallContractConnect(queryChainId ? queryChainId : chainId);

  useEffect(() => {
    const fetch = async () => {
      try {
        const data = await mc.aggregate([
          mcc.methods.getEthBalance(account),
          poolContract.methods.contributionOf(account),
          poolContract.methods.userAvalibleClaim(account),
          poolContract.methods.isInsurance(account)

        ]);

        console.log(data);

        setStats({
          balance: data[0] / Math.pow(10, 18),
          contributionOf: data[1] / Math.pow(10, 18),
          userAvalibleClaim: data[2] / Math.pow(10, 18),
          haveInsurance: data[3]
        })
      }
      catch (err) {
        toast.error(err.reason)
        // history.push('/sale-list');
      }
    }

    if (mc && account) {
      fetch();
    }
    else {
      setStats({
        balance: '',
        contributionOf: '',
        userAvalibleClaim: '',
        haveInsurance: false
      })
    }
    // eslint-disable-next-line
  }, [account, updater, chainId]);

  return stats;
}


