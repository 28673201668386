import React, { useState } from 'react';
import Opensale from './component/Opensale';
import PastSale from './component/PastSale';
import UpcomingSale from './component/UpcomingSale';

export default function PrivateProjectList() {
    const [chainSelect,setChainSelect] = useState({
        chainId : null,
        name : 'All Block Chain'
    });

    const [search,setSearch] = useState('null');

    const handleChainChanged = (e) =>{
        setChainSelect({
            chainId : e.target.id,
            name : e.target.name
        });
    }

    const handleSearch = (e) => {
        setSearch(e.target.value);
    }

    return (
        <React.Fragment>
            {/* <!-- Breadcrumbs Section Start --> */}
            <div className="gamfi-breadcrumbs-section">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-5">
                            <div className="breadcrumbs-area sec-heading">
                                <div className="sub-inner mb-15">
                                    <span className="sub-title">Projects</span>
                                    <img className="heading-left-image" src="assets/images/icons/steps.png" alt="Steps-mage" />
                                </div>
                                <h2 className="title mb-0">Explore ICOs/IDOs</h2>
                            </div>
                        </div>
                        <div className="col-lg-7 breadcrumbs-form md-pt-30">
                            <form>
                                <input type="text" value={search === 'null' ? '' : search } onChange={(e)=>{handleSearch(e)}} name="search" placeholder="Search by name, token, address" />
                                <span className="submit"><i className="icon-search"></i></span>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- Breadcrumbs  Section End --> */}

            {/* <!-- Previous Section Start --> */}
            <div className="gamfi-previous-section pt-70 pb-120 md-pb-80">
                <div className="container">
                    <div className="project-menu-area d-flex align-items-center justify-content-between">
                        <div className="project-left-menu">
                            <ul className="nav" id="myTab" role="tablist">
                                <li className="nav-item" role="presentation">
                                    <button className="tab-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#open-igo" type="button" role="tab" aria-controls="open-igo" aria-selected="true">Active</button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button className="tab-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#upcoming" type="button" role="tab" aria-controls="upcoming" aria-selected="false">Upcoming</button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button className="tab-link" id="contact-tab" data-bs-toggle="tab" data-bs-target="#past-igo" type="button" role="tab" aria-controls="past-igo" aria-selected="false">Past</button>
                                </li>
                            </ul>
                        </div>
                        <div className="project-right-menu">
                            <ul className="al-access-menu">
                                <li className="block"><a href="#sec">{chainSelect.name ? chainSelect.name : 'All Block Chain '}</a>
                                    <ul className="sub-menu">
                                        <li><a href="#sec" name="All Block Chain" id="null" onClick={(e) => handleChainChanged(e)}>All Block Chain</a></li>
                                        {/* <li><a href="#sec" name="Binance (BSC)" id="56" onClick={(e) => handleChainChanged(e)}><img src="assets/images/project/previous-image.png" alt="icon" /> Binance (BSC)</a></li> */}
                                        <li><a href="#sec" name="Test Binance (TBSC)" id="97" onClick={(e) => handleChainChanged(e)}><img src="assets/images/project/previous-image.png" alt="icon" /> Test Binance (TBSC)</a></li>
                                        <li><a href="#sec" name="Ropsten Ethereum (RETH)" id="3" onClick={(e) => handleChainChanged(e)}><img src="assets/images/project/previous-image2.png" alt="icon" /> Rinkeby Ethereum (RETH)</a></li>
                                        {/* <li><a href="#sec" name="Ethereum (ETH)"  id="1" onClick={(e) => handleChainChanged(e)}><img src="assets/images/project/previous-image2.png" alt="icon" /> Ethereum (ETH)</a></li> */}
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </div>
                    {/* <!-- Tab Menu Section End --> */}

                    {/* <!-- Tab Content Section Start --> */}
                    <div className="tab-content" id="myTabContent">
                        {/* <!-- Open ICO Content Start --> */}
                        <Opensale chainSelect={chainSelect} search={search} />
                        {/* <!-- Open ICO Content End --> */}

                        {/* <!-- Upcoming Content Start --> */}
                        <UpcomingSale chainSelect={chainSelect} search={search} />
                        {/* <!-- Upcoming Content Start --> */}

                        {/* <!-- Past ICO Content Start --> */}
                        <PastSale chainSelect={chainSelect} search={search} />
                    </div >
                </div >
            </div >
            {/* <!-- Previous Section End --> */}
        </React.Fragment >
    )
}
