import { defaultWagmiConfig } from "@web3modal/wagmi/react/config";
import { chains } from "./hooks/constant";

// 1. Get projectId
export const projectId = process.env.REACT_APP_PROJECT_ID;

// 2. Create a metadata object - optional
const metadata = {
    name: process.env.REACT_APP_PROJECT_NAME,
    description: process.env.REACT_APP_PROJECT_DESC,
    url: process.env.REACT_APP_PROJECT_URL,
    icons: [process.env.REACT_APP_PROJECT_ICON]
}

export const config = defaultWagmiConfig({
    chains,
    projectId,
    metadata,
    auth : {
        socials : [],
        email : false
    }
});