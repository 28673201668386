import { avalanche, bsc, mainnet, polygon } from "wagmi/chains";

export const trimAddress = (addr) => {
  if (addr !== '' && typeof addr !== 'undefined') {
    return `${addr.substring(0, 6)}...${addr.substring(addr.length - 4)}`;
  }
  else {
    return ``;
  }
}

//mainnet
// V1 Staking
export const multicallAddress = "0x2cc8fe3e04d7b1edaeb42e2e73f78c4cac074116";
export const tokenStakingAddress = "0x022c8bb07d866af73c99604bcebc40e9f8cfdb95";
export const tokenAddress = "0x02137Da913f50dedf6F06D5a7668670bb0f023B9";

// v2 Staking
export const multicallAddressv2 = "0x2cc8fe3e04d7b1edaeb42e2e73f78c4cac074116";
export const tokenStakingAddressv2 = "0xf6c491b7a71be330f11ae7730e114968932d68ea";
export const tokenAddressv2 = "0x02137Da913f50dedf6F06D5a7668670bb0f023B9";


export const nftstakingAddress = "0x7D689C15ac73Db227309dc55E75cF8Fe3d60D9D0";
export const Api_Baseurl = "https://launchpad.blockstar.tech/"
// export const Api_Baseurl = "http://localhost:8080/"


//Launchpad Contract

export const contract = {
  1: {
    poolfactory: "0xee52fe740b40FA92D2bb639dc5Cb41f602688b89",
    poolmanager: "0xE2331ff74b5930d7084723082dC4E0B0B502eD23",
    routeraddress: "0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D",
    multicallAddress: "0x5e227AD1969Ea493B43F840cfF78d08a6fc17796",
    lockAddress: "0x8A30f81E416c95A3028A56C4d39F28Ee76f224bE",
    routername: "Uniswap"
  },
  56 : {
    poolfactory: "0x22a937087933d3d5c0542ec3eb780c0333d8d853",
    poolmanager: "0xAbB0FFB2d1Dd881c39Ab9C67F19fE9748C3f2969",
    routeraddress: "0x10ed43c718714eb63d5aa57b78b54704e256024e",
    multicallAddress: "0x2cc8fe3e04d7b1edaeb42e2e73f78c4cac074116",
    lockAddress: "0x131f49Bd24e08e78558aC90C60575Bd825AE2e32",
    routername: "Pancakeswap"
  },
  // 97 : {
  //   poolfactory: "0x22A937087933D3d5C0542Ec3EB780c0333D8D853",
  //   poolmanager: "0xAbB0FFB2d1Dd881c39Ab9C67F19fE9748C3f2969",
  //   routeraddress: "0x10ed43c718714eb63d5aa57b78b54704e256024e",
  //   multicallAddress: "0xa54fe4a3dbd1eb21524cd73754666b7e13b4cb18",
  //   lockAddress: "0x131f49Bd24e08e78558aC90C60575Bd825AE2e32",
  //   routername: "Pancakeswap"
  // },
  137 : {
    poolfactory: "0xD8771bF13ef96a6e86E6C7989F366f3C7aC63dB6",
    poolmanager: "0x8a30f81e416c95a3028a56c4d39f28ee76f224be",
    routeraddress: "0xa5e0829caced8ffdd4de3c43696c57f7d7a678ff",
    multicallAddress: "0x4d76592d83585cf00792057749b03f97964668e8",
    lockAddress: "0xe685b7652ffeade7d19177f482ebd0752f016b07",
    routername: "Quickswap"
  },
  43114 : {
    poolfactory: "0x9Eb57fC71dF47b93eE86223f9e92d394403124B5",
    poolmanager: "0x522F595dA6Ac21134F82b390C124CBE3089A2Bc5",
    routeraddress: "0x60ae616a2155ee3d9a68541ba4544862310933d4",
    multicallAddress: "0xcA11bde05977b3631167028862bE2a173976CA11",
    lockAddress: "0xD8771bF13ef96a6e86E6C7989F366f3C7aC63dB6",
    routername: "Trader Joe Swap"
  },
  // 55 : {
  //   poolfactory: "0xc5DF313d87111Ce787BA7C4F5f43EBf3EB3a8675",
  //   poolmanager: "0x7087e5eB823A9b01Dc556Ede7e7827Dfc2D1A4A9",
  //   routeraddress: "0x91E8AB60a6e836cC0F76d2Cce5DC3B214d111543",
  //   multicallAddress: "0xBF429308D450182B13afB96413ffc6855c3388C9",
  //   lockAddress: "0xF1eF37C77F32a2806D413eB3178D4b3E182A8420",
  //   routername: "BlockStar Swap"
  // },
  5512 : {
    poolfactory: "0x4d76592D83585Cf00792057749B03f97964668E8",
    poolmanager: "0xBF429308D450182B13afB96413ffc6855c3388C9",
    routeraddress: "0x91E8AB60a6e836cC0F76d2Cce5DC3B214d111543",
    multicallAddress: "0x3c9d85F5C95E40C52980a8648397ca6E7cfA7932",
    lockAddress: "0x49fb061A8332777D10c3A591168db7091afd6f95",
    routername: "BlockStar Swap"
  },

  'default': {
    poolfactory: "0x4d76592D83585Cf00792057749B03f97964668E8",
    poolmanager: "0xBF429308D450182B13afB96413ffc6855c3388C9",
    routeraddress: "0x91E8AB60a6e836cC0F76d2Cce5DC3B214d111543",
    multicallAddress: "0x3c9d85F5C95E40C52980a8648397ca6E7cfA7932",
    lockAddress: "0x49fb061A8332777D10c3A591168db7091afd6f95",
    routername: "BlockStar Swap"
  }
}


export const blockstar = {
  id: 5512,
  name: 'BlockStar',
  network: 'blockstar',
  nativeCurrency: {
    decimals: 18,
    name: 'BlockStar',
    symbol: 'BST',
  },
  rpcUrls: {
    public: { http: ['https://mainnet-rpc.blockstar.one'] },
    default: { http: ['https://mainnet-rpc.blockstar.one'] },
  },
  blockExplorers: {
    etherscan: { name: 'BlockStarScan', url: 'https://scan.blockstar.one' },
    default: { name: 'BlockStarScan', url: 'https://scan.blockstar.one' },
  },
  contracts: {
    multicall3: {
      address: '0x3c9d85F5C95E40C52980a8648397ca6E7cfA7932',
      blockCreated: 12230,
    },
  }
}


export const chains = [bsc,mainnet,polygon,avalanche,blockstar]



