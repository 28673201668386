import bnbImg from './images/bnb.svg';
import emberImg from './images/ember.svg';

export const tokenList = [
    {
        name : 'BNB',
        address : '',
        decimals : 18,
        symbol : 'BNB',
        logoURI : bnbImg
    },
    {
        name : 'EMBER',
        address : '0x36a8fcb1f8bca02dc74eb34281de3b9143eae8e3',
        decimals : 18,
        symbol : 'EMBER',
        logoURI : emberImg
    }
    

]  