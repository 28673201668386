import { useEffect, useState } from "react"
import EmberAbi from '../json/Ember.json';
import { MulticallContractWeb3, multiCallContractConnect } from "../hooks/useContracts";
import { getWeb3 } from "../hooks/connectors";
import { useHistory } from 'react-router-dom';
import { toast } from "react-toastify";
import { supportNetwork } from "../hooks/network";
import { useAccount } from "wagmi";


export const useCommonStats = (update) => {
  const { chainId  } = useAccount();
  let history = useHistory();

  const [stats, setStats] = useState({
    endTime: 0,
    startTime: 0,
    hardCap: 0,
    softCap: 0,
    liquidityListingRate: 0,
    liquidityLockDays: 0,
    liquidityPercent: 0,
    liquidityUnlockTime: 0,
    maxContribution: 0,
    poolDetails: 0,
    poolState: 0,
    rate: 0,
    remainingContribution: 0,
    tgeLockDuration: 0,
    tgeTime: 0,
    tgeTokenReleasePer: 0,
    tgeTokensRelease: 0,
    token: 0,
    tokensReleaseEachCycle: 0,
    totalClaimed: 0,
    totalRaised: 0,
    totalVestingTokens: 0,
    useWhitelisting: 0,
    minContribution: 0,
    tokenName: '',
    tokenDecimal: 0,
    tokenSymbol: 0,
    tokenSupply: 0,
    percentageRaise: 0,
    refundType: 0,
    eachvestingPer: 0,
    cycle: 0,
    poolAddress: 0,
    governance: 0,
    kyc: false,
    audit: false,
    auditStatus: false,
    kycStatus: false,
    partnerList: [],
    totalPresaleToken: 0,
    currencySymbol: supportNetwork[56] ? supportNetwork[56].symbol : supportNetwork['default'].symbol,
    insurcePrice: 0,
    kycLink: '#',
    auditLink: '#'
  });

  const mc = MulticallContractWeb3(56);

  useEffect(() => {
    const fetch = async () => {
      try {

        setStats({
          "endTime": "1663430400",
          "startTime": "1662496200",
          "hardCap": 398.5,
          "softCap": 200,
          "liquidityListingRate": 14477367.049,
          "liquidityLockDays": "2592000",
          "liquidityPercent": "80",
          "liquidityUnlockTime": "0",
          "maxContribution": 24,
          "poolDetails": "https://pbs.twimg.com/profile_images/1562533399520505856/ICZWZvPQ_400x400.jpg$#$https://blockstar.site/page/view-group-profile?id=64$#$undefined$#$$#$https://twitter.com/PocProof$#$$#$https://t.me/POCTOKEN$#$$#$$#$$#$https://www.youtube.com/watch?v=2K291GgDR4Q$#$We glow to empower new and recovering projects in the blockchain and cryptocurrency space through digital leadership, immersive experience design, and clean code. Ember is a driving force of technological advancement through creative thinking, technical advisory, digital governance, and a deep-rooted desire to bring education, stability, and trust to the blockchain industry.",
          "poolState": "0",
          "rate": 14477367.049,
          "remainingContribution": 137.61644941741346,
          "tgeLockDuration": "0",
          "tgeTime": "0",
          "tgeTokenReleasePer": "0",
          "tgeTokensRelease": 0,
          "token": "0x36a8fcb1f8bca02dc74eb34281de3b9143eae8e3",
          "tokensReleaseEachCycle": "0",
          "totalClaimed": "0",
          "totalRaised": 398.5,
          "totalVestingTokens": 0,
          "useWhitelisting": false,
          "minContribution": 0.01,
          "tokenName": "Ember",
          "tokenDecimal": "18",
          "tokenSymbol": "EMBER",
          "percentageRaise": 100,
          "tokenSupply": 10000000000,
          "refundType": "0",
          "eachvestingPer": "0",
          "cycle": "0",
          "poolAddress": "0xf8e9BD606e8cF581AD6A0E31C414303Fe90E1B76",
          "governance": "0xCE199E3F4e06623B9425b66a92f97B63877056F9",
          "kyc": true,
          "audit": true,
          "auditStatus": true,
          "kycStatus": true,
          "partnerList": [
            [
              "0xf8e9BD606e8cF581AD6A0E31C414303Fe90E1B76",
              "0xbB44943dE694b34B5162cC56775804b4802d2882",
              "100000000000000000",
              "AMA",
              "https://pbs.twimg.com/profile_images/1556926666345267200/ZgLPmNP__400x400.jpg",
              "Legit Crypto Lounge",
              false,
              false
            ],
            [
              "0xf8e9BD606e8cF581AD6A0E31C414303Fe90E1B76",
              "0x2D2438b056B0b3bDC57884C6553D392cD36CB64A",
              "100000000000000000",
              "AMA/Podcast",
              "https://blockstar.site/pwa_image/blockstar_pwa.png",
              "BlockStar",
              false,
              false
            ]
          ],
          "totalPresaleToken": 12540000,
          "currencySymbol": "BNB",
          "PresalePer": 45,
          "LiquidityPer": 53,
          "BurntPer": 0,
          "LockPer": 2,
          "insurcePrice": 39.95,
          "kycLink": "https://blockstar.site/image_transcoder.php?o=bx_timeline_photos_big&h=1559&dpx=1&t=1661870902",
          "auditLink": ""
        });


      }
      catch (err) {
        toast.error('wrong network selected !');
        history.push('/sale-list');
      }
    }

    if (mc) {
      fetch();
    }
    else {
      setStats({
        endTime: 0,
        startTime: 0,
        hardCap: 0,
        softCap: 0,
        liquidityListingRate: 0,
        liquidityLockDays: 0,
        liquidityPercent: 0,
        liquidityUnlockTime: 0,
        maxContribution: 0,
        poolDetails: 0,
        poolState: 0,
        rate: 0,
        remainingContribution: 0,
        tgeLockDuration: 0,
        tgeTime: 0,
        tgeTokenReleasePer: 0,
        tgeTokensRelease: 0,
        token: 0,
        tokensReleaseEachCycle: 0,
        totalClaimed: 0,
        totalRaised: 0,
        totalVestingTokens: 0,
        useWhitelisting: 0,
        minContribution: 0,
        tokenName: '',
        tokenDecimal: 0,
        tokenSymbol: 0,
        percentageRaise: 0,
        tokenSupply: 0,
        refundType: 0,
        eachvestingPer: 0,
        cycle: 0,
        poolAddress: 0,
        governance: 0,
        kyc: false,
        audit: false,
        auditStatus: false,
        kycStatus: false,
        partnerList: [],
        totalPresaleToken: 0,
        insurcePrice: 0,
        kycLink: '#',
        auditLink: '#',
        currencySymbol: supportNetwork[56] ? supportNetwork[56].symbol : supportNetwork['default'].symbol
      })
    }
    // eslint-disable-next-line
  }, [update, chainId]);

  return stats;
}

export const useAccountStats = (updater) => {
  const { chainId, address:account  } = useAccount();

  let history = useHistory();
  let urlAddress = "0x055252fae32167fd1de93d52e58f2c0839e38466";
  let web3 = getWeb3(56);
  let poolContract = new web3.eth.Contract(EmberAbi, urlAddress);

  const [stats, setStats] = useState({
    balance: '',
    contributionOf: '',
    userAvalibleClaim: '',
    haveInsurance: false,
    paused: true,
    tokenAddress: 0,
    claimed: true
  });

  const mc = MulticallContractWeb3(56);
  const mcc = multiCallContractConnect(56);

  useEffect(() => {
    const fetch = async () => {
      try {
        const data = await mc.aggregate([
          mcc.methods.getEthBalance(account),
          poolContract.methods._CumulativeClaimInfo(account),
          poolContract.methods.paused(),
        ]);

        setStats({
          balance: data[0] / Math.pow(10, 18),
          contributionOf: data[1][1] ? 0 : data[1][2] / Math.pow(10, 18),
          userAvalibleClaim: data[1][2] / Math.pow(10, 18),
          paused: data[2],
          haveInsurance: false,
          tokenAddress: urlAddress,
          claimed: data[1][1]
        })
      }
      catch (err) {
        toast.error(err.reason)
        history.push('/sale-list');
      }
    }

    if (account) {
      fetch();
    }
    else {
      setStats({
        balance: '',
        contributionOf: '',
        userAvalibleClaim: '',
        haveInsurance: false,
        paused: true,
        tokenAddress: 0,
        claimed: true
      })
    }
    // eslint-disable-next-line
  }, [account, updater, chainId]);

  return stats;
}


