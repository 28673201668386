import React from "react";
import { trimAddress } from '../hooks/constant';
import { supportNetwork } from "../hooks/network";
import { useAccount } from 'wagmi';
import { useWeb3Modal } from "@web3modal/wagmi/react";





export const Connect = function () {
    const { open } = useWeb3Modal()
    const { address, isConnected , chainId  } = useAccount()



    return (
        <React.Fragment>
            <li>
                {address && isConnected ? (
                    <>
                        <button type="button" className="readon white-btn hover-shape" onClick={() => open({ view: "Networks" })}>
                            <span className="btn-text">
                                <img src={`../assets/images/${(chainId && supportNetwork[chainId]) ? supportNetwork[chainId].image : supportNetwork['default'].image}`} alt="Switch Network" className="sc-dPaNzc fqbJCS" style={{ "width": "20px" }} />
                                {(chainId && supportNetwork[chainId]) ? supportNetwork[chainId].name : supportNetwork['default'].name}
                            </span>
                            <span className="hover-shape1"></span>
                            <span className="hover-shape2"></span>
                            <span className="hover-shape3"></span>
                        </button>
                        <button type="button" className="readon white-btn hover-shape" >

                            <span className="btn-text" onClick={() => {
                                open();
                            }} >{trimAddress(address)}</span>


                            <span className="hover-shape1"></span>
                            <span className="hover-shape2"></span>
                            <span className="hover-shape3"></span>
                        </button>
                    </>
                ) : (
                    <button type="button" className="readon white-btn hover-shape" onClick={() => {
                        open()
                    }}>
                        <img src="../assets/images/icons/connect.png" alt="Icon" />
                        <span className="btn-text">Connect wallet</span>
                        <span className="hover-shape1"></span>
                        <span className="hover-shape2"></span>
                        <span className="hover-shape3"></span>
                    </button>
                )}
            </li>
        </React.Fragment>
    );
};

export default Connect;