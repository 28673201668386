import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import ScrollToTop from "./component/ScrollToTop";
import Footer from "./component/Footer";
import Header from "./component/Header";
import Home from "./pages/Home";
import IcoApply from "./pages/IcoApply";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PreSale from "./pages/launchpadApply/Presale";
import PrivateSale from "./pages/launchpadApply/PrivateSale";
import ProjectList from "./pages/launchpadApply/presaleview/ProjectList";
import PrivateProjectList from "./pages/launchpadApply/privatesaleview/PrivateProjectList";
import DetailsComp from "./pages/launchpadApply/presaleview/DetailsComp";
import DetailsCompPrivatesale from "./pages/launchpadApply/privatesaleview/DetailsCompPrivatesale";
import MainLock from "./pages/lock/MainLock";
import TokenLockList from "./pages/locklist/TokenLockList";
import LockView from "./pages/locklist/LockView";
import LockRecord from "./pages/locklist/LockRecord";
import Fairsale from "./pages/launchpadApply/Fairsale";
import DetailsFairComp from "./pages/launchpadApply/fairsaleview/DetailsFairComp";
import MyTokenLock from "./pages/locklist/MyTokenLock";
import MyLpLock from "./pages/locklist/MyLpLock";
import EmberPresale from "./pages/EmberPresale";
// import Liquidity from "./pages/Liquidity/Liquidity";
// import AddLiquidity from "./pages/Liquidity/AddLiquidity";
import { ContextProvider } from './pages/Liquidity/context/context';
// import ImportLiquidity from "./pages/Liquidity/ImportLiquidity";
// import RemoveLiquidity from "./pages/Liquidity/RemoveLiquidity";


function App() {

  return (
    <div className="App">
      <ContextProvider>
        <Router>
          <ScrollToTop>
            <ToastContainer pauseOnFocusLoss={false} />
            <Header />
            <Switch>
              <Route exact path="/">
                <Home />
              </Route>

              <Route path="/presale-details">
                <DetailsComp />
              </Route>
              <Route path="/privatesale-details">
                <DetailsCompPrivatesale />
              </Route>
              <Route path="/fairlaunch-details">
                <DetailsFairComp />
              </Route>
              <Route path="/ember-presale">
                <EmberPresale />
              </Route>

              <Route exact path="/ico-apply"><IcoApply /></Route>
              <Route exact path="/presale"><PreSale /></Route>
              <Route exact path="/privatesale"><PrivateSale /></Route>
              <Route exact path="/fairsale"><Fairsale /></Route>
              <Route exact path="/sale-list"><ProjectList /></Route>
              <Route exact path="/private-list"><PrivateProjectList /></Route>
              <Route exact path="/lock"><MainLock /></Route>
              <Route exact path="/lock-list"><TokenLockList /></Route>
              <Route exact path="/lock-details/:id"><LockView /></Route>
              <Route exact path="/lock-record/:id"><LockRecord /></Route>
              <Route exact path="/my-token-lock"><MyTokenLock /></Route>
              <Route exact path="/my-lp-lock"><MyLpLock /></Route>
              {/* <Route exact path="/liquidity"><Liquidity /></Route>
              <Route exact path="/add-liquidity"><AddLiquidity /></Route>
              <Route exact path="/import-liquidity"><ImportLiquidity /></Route>
              <Route exact path="/remove-liquidity/:id"><RemoveLiquidity /></Route> */}
            </Switch>
            <Footer />
          </ScrollToTop>
        </Router>
      </ContextProvider>
    </div>
  );
}

export default App;
