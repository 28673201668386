import Web3 from "web3";
import { supportNetwork  } from './network';

export const getWeb3 = (chainId) =>{
  let setRpc = supportNetwork[chainId] ? supportNetwork[chainId].rpc : supportNetwork['default'].rpc;
  return new Web3(setRpc);
}

export const supportChainId = Object.keys(supportNetwork).map(function (key) {
  return parseInt(key);
});

