import React, { useState,useContext, useEffect } from 'react';
import './index.css';
import Step4 from './privatesale/Step4';
import Step1 from './privatesale/Step1';
import Step2 from './privatesale/Step2';
import Step3 from './privatesale/Step3';
import Step5 from './privatesale/Step5';
import Context from "./privatesale/context/Context";
import {defaultValue} from './privatesale/context/defaults';
import { useAccount } from 'wagmi';


export default function PrivateSale() {

    const appContext = useContext(Context);
    const [context, setContext] = useState(appContext);
    const { chainId, address:account  } = useAccount();

    const setValue = value => {
        console.log({ ...context, value });
        setContext({ ...context, value });
    };

    useEffect(()=>{ 
        setContext({ ...context, value : {...defaultValue} });
        // eslint-disable-next-line
    },[chainId,account])


    const btnNextStep = (e) => {
        
        if (context.value.step < context.value.maxStep) {
            setValue({ ...context.value  , "step" :  parseInt(context.value.step + 1)});
        }
    }

    const btnPrevStep = (e) => {
        
        if (context.value.step > 1) {
            setValue( {  ...context.value ,"step" :  parseInt(context.value.step - 1)});
        }
    }


    const state = {
        ...context,
        setValue: setValue,
        btnNextStep : btnNextStep,
        btnPrevStep : btnPrevStep
    };



    



    return (
        <Context.Provider value={state}>
            <React.Fragment>
                <div className="gamfi-breadcrumbs-section">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-5">
                                <div className="breadcrumbs-area sec-heading">
                                    <div className="sub-inner mb-15">
                                        <span className="sub-title blue-color">PrivateSale Launchpad</span>
                                        <img className="heading-left-image" src="assets/images/icons/steps.png" alt="StepsImage" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="signup-step-container">
                    <div className="container">
                        <div className="row d-flex justify-content-center">
                            <div className="col-10">
                                <div className="wizard">
                                    <div className="wizard-inner">
                                        <div className="connecting-line"></div>
                                        <ul className="nav nav-tabs" role="tablist">
                                            <li role="presentation" className={`${context.value.step === 1 ? 'active' : 'disabled'}`}>
                                                <a href="#step1" data-toggle="tab" aria-controls="step1" role="tab" aria-expanded="true"><span className="round-tab">1 </span> <i>Verify Token</i></a>
                                            </li>
                                            <li role="presentation" className={`${context.value.step === 2 ? 'active' : 'disabled'}`}>
                                                <a href="#step2" data-toggle="tab" aria-controls="step2" role="tab" aria-expanded="false"><span className="round-tab">2</span> <i>DeFi Launchpad Info</i></a>
                                            </li>
                                            <li role="presentation" className={`${context.value.step === 3 ? 'active' : 'disabled'}`}>
                                                <a href="#step3" data-toggle="tab" aria-controls="step3" role="tab"><span className="round-tab">3</span> <i>Add Additional Info</i></a>
                                            </li>
                                            <li role="presentation" className={`${context.value.step === 4 ? 'active' : 'disabled'}`}>
                                                <a href="#step4" data-toggle="tab" aria-controls="step4" role="tab"><span className="round-tab">4</span> <i>Marketing Info</i></a>
                                            </li>
                                            <li role="presentation" className={`${context.value.step === 5 ? 'active' : 'disabled'}`}>
                                                <a href="#step4" data-toggle="tab" aria-controls="step5" role="tab"><span className="round-tab">5</span> <i>Finish</i></a>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="card stepcard">
                                        <div className="card-body">
                                            <form className="login-box">
                                                <div className="tab-content" id="main_form">
                                                    <Step1 />
                                                    <Step2 />
                                                    <Step3 />
                                                    <Step4 />
                                                    <Step5/>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                    <div className='mt-4'>
                                        Disclaimer: The information provided shall not in any way constitute a recommendation as to whether you should invest in any product discussed. We accept no liability for any loss occasioned to any person acting or refraining from action as a result of any material provided or published.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </React.Fragment>
        </Context.Provider>
    )
}
