import React, { useContext, useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import { Context } from "../context/context";
import localStorage from 'local-storage'; 





export default function SettingModal() {

    const { settingshow, setSettingShow } = useContext(Context);
    const [slippage,setSlipage] = useState(localStorage.get('slippage') === null ? 1 : localStorage.get('slippage'));
    const [deadline,setDeadline] = useState(localStorage.get('deadline') === null ? 20 : localStorage.get('deadline'));
    
    const handleSlipageSelect = (amount) => {
        localStorage.set('slippage',amount);
        setSlipage(amount);
    }

    const handleDeadline = (amount) => {
        localStorage.set('deadline',amount);
        setDeadline(amount);
    }
    
    return (
        <>
            <Modal
                show={settingshow}
                onHide={() => setSettingShow(false)}
                size="ms"
                aria-labelledby="example-modal-sizes-title-lg"
            >

                <Modal.Header closeButton={true}>
                    <Modal.Title>Settings</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='d-flex align-items-center mb-2'>
                        <div className='set-title'>Slippage tolerance</div>

                    </div>
                    <div className="hMVoU">
                        <button onClick={()=>handleSlipageSelect(0.1)} className={`${slippage === 0.1 ? 'active-btn' : 'hxRsyf' }`} scale="sm">0.1%</button>
                        <button onClick={()=>handleSlipageSelect(0.5)} className={`${slippage === 0.5 ? 'active-btn' : 'hxRsyf' }`} scale="sm">0.5%</button>
                        <button onClick={()=>handleSlipageSelect(1)} className={`${slippage === 1 ? 'active-btn' : 'hxRsyf' }`} scale="sm">1%</button>
                        <div className="gpLyXn">
                            <div width="76px" className="sc-iCoGMd eEEGLZ">
                                <input onChange={(e) => handleSlipageSelect(e.target.value)} autoCapitalize="off" value={slippage} placeholder="5"  type="number" className='faorLE' />
                            </div>
                            <div color="primary" className="sc-gtsrHT fZIa-Dd">%</div>
                        </div>
                    </div>
                    <div color="failure" className="tra-fail">Your transaction may fail</div>
                    <div className="sc-fxNNfJ jUsJDi">
                        <div className="sc-fXvjs endHJt">
                            <div color="text" className="hpGOMD">Transaction deadline</div>

                        </div>
                        <div className="kQbLAY">
                            <input autoCapitalize="off" onChange={(e)=>handleDeadline(e.target.value)} value={deadline} placeholder="9.00" type="number" className='gXtQnD' />
                            <div color="text" className="hpGOMD ms-1">Minutes</div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}