import React, { useState } from 'react';
import HashLoader from "react-spinners/HashLoader";
import { Link } from 'react-router-dom';
import { useMyTokenLockStats } from './helper/useStats';
import { useAccount } from 'wagmi';



export default function MyTokenLock() {
    const [updater,setUpdater] = useState({loading : true});
    const stats = useMyTokenLockStats(updater);
    const { chainId  } = useAccount();

    return (
        <React.Fragment>
            <div class="gamfi-breadcrumbs-section">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-5">
                            <div class="breadcrumbs-area sec-heading">
                                <div class="sub-inner mb-15">
                                    <span class="sub-title">Lock</span>
                                    <img class="heading-left-image" src="../assets/images/icons/steps.png" alt="Steps" />
                                </div>
                                <h2 class="title mb-0">My Lock Token</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>



            <div class="igo-rankging-table-list pb-140 md-pb-60 pt-55">
                <div class="container">
                    <table class="table">
                        <thead>
                            <tr>
                                <th>No</th>
                                <th>Name</th>
                                <th>Amount</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {stats.loading ? (
                                <div className='row'>
                                    <div className='col-12 d-flex justify-content-between'>
                                        <HashLoader size="100" color="#fff" cssOverride={{ "left": "50%", "top": "20%" }} />
                                    </div>
                                </div>
                            ) : (
                                stats.tokenList.length > 0 ? (
                                    stats.tokenList.slice(0).reverse().map((rowdata, index) => {
                                        return (
                                            <tr>
                                                <td>{stats.page > 0 ? stats.page*stats.pageSize + 1 + index : index + 1 }</td>
                                                <td class="image-row"> {rowdata.name} ~ {rowdata.symbol}</td>
                                                <td>{rowdata.amount ? rowdata.amount / Math.pow(10, rowdata.decimals) : "0"} {rowdata.symbol}</td>
                                                <td><Link style={{ "color": "#47c8f2" }} to={`/lock-details/${rowdata.token}${chainId ? `?chainid=${chainId}` : ''}`}>View</Link></td>

                                            </tr>
                                        )
                                    })
                                ):(
                                    <tr>
                                        <td style={{"textAlign": "center"}} colspan="4">No Record Found</td>
                                    </tr>
                                )
                            )}
                        </tbody>
                    </table>
                    <div className="gamfi-navigation text-center mt-10">
                        <ul>
                            <li>
                                {stats.page > 0 &&
                                    <a href="#sec" onClick={(e) => setUpdater({page : stats.page - 1 , pageSize : stats.pageSize , loading : true})}>
                                        <i className="icon-Vector mr-2"></i>Previous
                                    </a>
                                }
                            </li>
                            {Math.floor(stats.allNormalTokenLockedCount / stats.pageSize) > parseFloat(stats.page) &&
                                < li >
                                    <a href="#sec" onClick={(e) => setUpdater({page : stats.page + 1 , pageSize : stats.pageSize , loading : true})}>
                                        Next<i className="icon-arrow_right ml-2"></i>
                                    </a>
                                </li>
                            }
                        </ul>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}
